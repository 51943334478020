import { usePathname, useSearchParams } from "next/navigation";
import { useMemo } from "react";

export default function usePath() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queryString = searchParams.toString();

  return useMemo(() => {
    // extract team_id and game_id from pathname
    const matches = pathname.match(/^\/t\/(\d+)(?:.*?\/g\/(\d+))?/);
    const team_id = matches?.[1] ? parseInt(matches[1], 10) : null;
    const game_id = matches?.[2] ? parseInt(matches[2], 10) : null;

    // extract additional game_ids from search parameters if game_id is not available
    const additionalGameIds = [...queryString.matchAll(/gid=(\d+)/g)].map(
      (match) => parseInt(match[1], 10)
    );
    const game_ids =
      typeof game_id === "number" ? [game_id] : additionalGameIds;

    return {
      pathname,
      isPathGames: /\/t\/\d+\/?$/.test(pathname),
      isPathGame: /\/t\/\d+\/g\/\d+.*$/.test(pathname),
      isPathReport: /\/t\/\d+\/report.*$/.test(pathname),
      isPathMerge: /\/t\/\d+\/merge$/.test(pathname),
      isPathValidate: /\/t\/\d+\/validate.*$/.test(pathname),
      isPathTeamSettings: /\/t\/\d+\/manage.*$/.test(pathname),
      isPathSupport: /^\/support\/?$/.test(pathname),
      isPathSupportSuccess: /^\/support\/success/.test(pathname),
      team_id,
      game_id,
      game_ids,
    };
  }, [pathname, queryString]);
}
