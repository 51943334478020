"use client";

import Footer from "../../components/Footer";
import { useSession, SessionContext } from "../hooks/useSession";
import { Container } from "react-bootstrap";
import { ReactNode, Suspense } from "react";
import { UserContext, useUsers } from "../hooks/useUsers";
import { ToastRequestTip } from "../../components/toasts/ToastRequestTip";

export default function SessionLayout({ children }: { children: ReactNode }) {
  const sessionContext = useSession({});
  const userContext = useUsers({ sessionUser: sessionContext?.user });

  return (
    <SessionContext.Provider value={sessionContext}>
      <UserContext.Provider value={userContext}>
        <Container fluid="sm">
          <div style={{ minHeight: "100vh" }} className="d-flex flex-column">
            {children}
            <Footer />
            <Suspense fallback={null}>
              <ToastRequestTip />
            </Suspense>
          </div>
        </Container>
      </UserContext.Provider>
    </SessionContext.Provider>
  );
}
