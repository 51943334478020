import { Toast, ToastContainer } from "react-bootstrap";
import { colorFuegoStatsBrown, colorFuegoStatsYellow } from "../../lib/colors";
import { FaHeart } from "react-icons/fa6";
import Link from "next/link";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../app/hooks/useUsers";
import usePath from "../../app/hooks/usePath";

export const ToastRequestTip = ({ delay = 60000 }: { delay?: number }) => {
  const { isLoading, showTipRequest } = useContext(UserContext);
  const [isVisible, setIsVisible] = useState(false);
  const { isPathSupport, isPathSupportSuccess } = usePath();

  useEffect(() => {
    if (!isLoading && showTipRequest()) {
      setIsVisible(true);
    }
  }, [isLoading, showTipRequest]);

  return (
    <ToastContainer
      position="bottom-center"
      className="pb-3 position-fixed"
      style={{ zIndex: 750 }}>
      <Toast
        onClose={() => setIsVisible(false)}
        show={isVisible && !isPathSupport && !isPathSupportSuccess}
        delay={delay}
        style={{
          color: colorFuegoStatsBrown,
          background: `linear-gradient(
            to bottom,
            color-mix(in srgb, ${colorFuegoStatsYellow} 40%, white 60%),
            ${colorFuegoStatsYellow},
            ${colorFuegoStatsYellow}
          )`,
          border: `2px solid ${colorFuegoStatsBrown}`,
        }}
        autohide
        animation>
        <Toast.Body
          className="lh-sm d-flex align-items-center justify-content-center text-nowrap"
          style={{ padding: "0.7em" }}>
          <FaHeart className="me-1 text-danger animation-beat" />
          <span className="fw-light me-1">Love Fuego Stats?</span>
          <Link
            href="/support/"
            className="fw-bolder"
            style={{
              color: colorFuegoStatsBrown,
            }}>
            Support the project.
          </Link>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
